export const state = {
  notifications: []
}

export const mutations = {
  PUSH_NOTIFICATION(state, value) {
    state.notifications.push({
      ...value,
      id: state.notifications.length + 1
    })
  },
  DELETE_NOTIFICATION(state, value) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== value.id
    )
  }
}

export const actions = {
  addNotification({ commit }, value) {
    commit('PUSH_NOTIFICATION', value)
  },
  deleteNotification({ commit }, value) {
    commit('DELETE_NOTIFICATION', value)
  }
}
