<template>
  <v-app
    id="main"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <Notification />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Notification from '@/components/notification/Notification'

export default {
  name: 'App',
  components: { Notification },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    }
  }
}
</script>
