import Vue from 'vue'
import VueApollo from 'vue-apollo'

import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, concat, split } from 'apollo-link'

import store from './../store/index'

Vue.use(VueApollo)

export function createProvider() {
  const httpLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_API_URL
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: store.state.user.session.idToken.jwtToken || null
      }
    })
    return forward(operation)
  })

  const cache = new InMemoryCache()

  const apolloClient = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache
  })

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient
  })

  return apolloProvider
}
