import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import VueTour from 'vue-tour'
import VueGtag from 'vue-gtag'

require('@/utilities/vue-tour.css')

import { createProvider } from './vue-apollo/vue-apollo'

Vue.config.productionTip = false
Vue.use(VueTour)
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
  },
  router
)

new Vue({
  router,
  vuetify,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App)
}).$mount('#app')
