<template>
  <div class="alert">
    <NotificationBar
      v-for="notification in this.$store.state.notification.notifications"
      :key="notification.id"
      :notification="notification"
    >
      {{ notification.message }}
    </NotificationBar>
  </div>
</template>

<script>
import NotificationBar from './NotificationBar'
export default {
  components: { NotificationBar }
}
</script>

<style scoped>
.alert {
  position: fixed;
  z-index: 250;
  bottom: 0em;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>
