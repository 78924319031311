const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { requiresLogout: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
    meta: { requiresLogin: true }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
    meta: { requiresLogout: true }
  },
  {
    path: '/home',
    component: () => import('../views/Home.vue'),
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: {}
      },
      {
        path: 'edit-profile',
        name: 'EditProfile',
        component: () => import('../views/EditProfile.vue'),
        meta: {}
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: () => import('../views/Analytics.vue'),
        meta: { limitAccess: true }
      },
      {
        path: 'manage-users',
        name: 'ManageUsers',
        component: () => import('../views/Management.vue'),
        meta: { limitAccess: true }
      },
      {
        path: 'data-entry',
        name: 'DataEntry',
        component: () => import('../views/UnderConstruction.vue'),
        meta: { limitAccess: true }
      },
      {
        path: 'under-construction',
        name: 'UnderConstruction',
        component: () => import('../views/UnderConstruction.vue'),
        meta: {}
      }
    ]
  },
  {
    path: '*',
    redirect: { name: 'Dashboard' }
  }
]

export default routes
