<template>
  <v-alert :type="notification.type" dismissible>
    {{ notification.message }}
  </v-alert>
</template>

<script>
export default {
  name: 'NotificationBar',
  props: {
    notification: Object
  },
  data() {
    return {
      timeout: null
    }
  },
  mounted() {
    this.timeout = setTimeout(
      () => this.$store.dispatch('deleteNotification', this.notification),
      5000
    )
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  }
}
</script>
