import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})

// setup protected routes
const DEFAULT_TITLE = 'BioDF Admin'
router.beforeEach((to, from, next) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
  store.dispatch('getSession')

  const authenticated = localStorage.getItem('authenticated')
  const groups = store.state.user.userAttributes['cognito:groups']
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (!authenticated) {
      next({ name: 'Login' })
    } else if (!groups || !groups.includes('Admin')) {
      store.dispatch('logout')
      location.replace('http://portal.bio-conversion.org/')
    }
  } else if (to.matched.some((record) => record.meta.requiresLogout)) {
    if (!!authenticated) {
      next({ name: 'Dashboard' })
    }
  }
  next()
})

export default router
