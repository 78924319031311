import UserPool from '@/plugins/UserPool'

export const state = {
  userAttributes: {},
  session: {}
}

export const mutations = {
  SET_USER_ATTRIBUTES(state, value) {
    state.userAttributes = value
  },
  SET_SESSION(state, value) {
    state.session = value
  }
}

export const actions = {
  getSession({ commit, dispatch }) {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          console.log('authentication failed: ', session) // DEBUG
        } else {
          console.log('authentication successful: ', session) // DEBUG
          commit('SET_SESSION', session)
          commit('SET_USER_ATTRIBUTES', session.idToken.payload)
        }
      })
    } else {
      console.log('authentication failed: user is undefined') // DEBUG
    }
  },
  logout({ commit }) {
    const user = UserPool.getCurrentUser()
    if (user) {
      localStorage.removeItem('authenticated')
      user.signOut()
    }
  }
}
